import {useMutation} from '@apollo/client';
import {useToast} from '@shopify/app-bridge-react';
import {useI18n} from '@shopify/react-i18n';
import {useCallback, useContext, useState} from 'react';

import {StaffMemberPermission} from '~/types/graphql/core-types';
import {type DismissableCardHandle} from '~/utils/constants';
import {createI18nConfig} from '~/utils/createI18nConfig';

import {SharedDataContext} from '../context';
import {DismissCardDocument} from '../graphql/DismissCardMutation.core.graphql.generated';
import {type DismissibleCardNetworkState} from '../types';

import translations from './translations';

export function useSharedDataContext() {
  const allData = useContext(SharedDataContext);
  const toast = useToast();
  const [i18n] = useI18n(createI18nConfig(translations));

  const [dismissCardNetworkState, setDismissCardNetworkState] =
    useState<DismissibleCardNetworkState>(
      allData?.dismissibleCardsLoading ? 'loading' : 'idle',
    );

  const [dismissCard] = useMutation(DismissCardDocument);

  if (!allData) {
    throw new Error(
      'No data found for SharedDataContext. Make sure you have a SharedDataContext above this component.',
    );
  }

  const {shop, staffMember, refetchDismissibleCards, ...restOfData} = allData;
  const {enabledFlags, ...restOfShop} = allData.shop;
  const {pin, adminPermissions, ...restOfStaffMember} = allData.staffMember;

  const hasPermissions = useCallback(
    (requiredPermissions: StaffMemberPermission[]) => {
      if (requiredPermissions.length === 0) {
        return true;
      }

      if (adminPermissions.length === 0) {
        return false;
      }

      return requiredPermissions.every((permission) =>
        adminPermissions.includes(permission),
      );
    },
    [adminPermissions],
  );

  const dismissCardCB = useCallback(
    async ({
      handle,
      ignoreError = false,
    }: {
      handle: DismissableCardHandle;
      ignoreError?: boolean;
    }) => {
      try {
        setDismissCardNetworkState('dismissing');
        const result = await dismissCard({
          variables: {handle},
        });

        await refetchDismissibleCards();

        return result;
      } catch {
        if (!ignoreError) {
          toast.show(i18n.translate('useSharedDataContext.errors.server'), {
            isError: true,
          });
        }
      } finally {
        setDismissCardNetworkState('idle');
      }
    },
    [dismissCard, refetchDismissibleCards, toast, i18n],
  );

  return {
    ...restOfData,
    shop: {
      ...restOfShop,
    },
    staffMember: {
      ...restOfStaffMember,
    },
    hasPermissions,
    dismissCard: dismissCardCB,
    dismissCardNetworkState,
  };
}
