import Bugsnag, {
  type BreadcrumbType,
  type NotifiableError,
  type OnErrorCallback,
} from '@bugsnag/js';
import {createContext} from 'react';

export type LogSeverity = 'info' | 'warning' | 'error';

/**
 * Send on-demand reports to bugsnag
 * @param err Error
 * @param severity Severity level on bugsnag. Can be info, warning or error. Defaults to warning.
 * @param errorCb Executed before sending the event to bugsnag. Can enrich the event with more data. If returns false, the event won't be sent
 */
export const notify = (
  err: NotifiableError,
  severity?: LogSeverity,
  errorCb?: OnErrorCallback,
) => {
  Bugsnag.notify(err, (event, ...args) => {
    if (severity) {
      event.severity = severity;
    }
    // Returning false will prevent the report to be sent to bugsnag
    return errorCb?.(event, ...args);
  });
};

export const leaveBreadcrumb = (message: string, type?: BreadcrumbType) => {
  Bugsnag.leaveBreadcrumb(message, {}, type);
};

export const BugsnagContext = createContext({
  notify,
  leaveBreadcrumb,
  addFeatureFlag: Bugsnag.addFeatureFlag,
});
