import {createContext} from 'react';

import {type Gid, type Scalar} from '~/types';
import {CurrencyCode, StaffMemberPermission} from '~/types/graphql/core-types';
import {type DismissableCardHandle} from '~/utils/constants';

interface ShopDetailsContext {
  id: string;
  name: string;
  contactEmail: string;
  currencyCode: CurrencyCode;
  url: Scalar<'URL'>;
  enabledFlags: Array<boolean>;
  myshopifyDomain: string;
  ianaTimezone: string;
}

export interface SharedDataContextType {
  shop: ShopDetailsContext;
  userId: number;
  staffMember: {
    email: string;
    pin: string;
    adminPermissions: StaffMemberPermission[];
  };
  unifiedAdminEnabled: boolean;
  app: {
    id: Gid<'AppInstallation'>;
    appStoreAppUrl: Scalar<'URL'>;
    handle: string;
  };
  dismissibleCardsLoading: boolean;
  dismissedCards: DismissableCardHandle[];
  refetchDismissibleCards: () => Promise<unknown>;
}

export const SharedDataContext = createContext<
  SharedDataContextType | undefined
>(undefined);
