import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type DismissCardMutationVariables = Types.Exact<{
  handle: Types.Scalars['String']['input'];
}>;

export type DismissCardMutation = {
  __typename: 'Mutation';
  resourceAlertDismiss: {
    __typename: 'ResourceAlertDismissPayload';
    userErrors: Array<{
      __typename: 'UserError';
      field: Array<string> | null;
      message: string;
    }>;
  } | null;
};

export const DismissCardDocument = gql`
  mutation DismissCard($handle: String!) {
    resourceAlertDismiss(handle: $handle) {
      userErrors {
        field
        message
      }
    }
  }
` as unknown as DocumentNode<DismissCardMutation, DismissCardMutationVariables>;
