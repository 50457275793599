interface IBetaFlag {
  betaFlag: string;
  optOutFlag: string | undefined;
}

// The hash value can be found inside Services Internal https://app.shopify.com/services/internal/betas
export const combinedListingConfigurationFlag = {
  betaFlag: 'eec94acc',
  optOutFlag: undefined,
} as const;

export const BETA_FLAGS_LIST = [
  combinedListingConfigurationFlag,
] as const satisfies Array<IBetaFlag>;

export const ALL_BETA_FLAG_VALUES = BETA_FLAGS_LIST.flatMap((beta) => [
  beta.betaFlag,
  beta.optOutFlag ?? '',
]);

type InternalBetaFlags = NonNullable<
  (typeof BETA_FLAGS_LIST)[number]['betaFlag']
>;
type InternalOptOutFlags = NonNullable<
  (typeof BETA_FLAGS_LIST)[number]['optOutFlag']
>;

export type BetaFlags = NonNullable<
  InternalBetaFlags | InternalOptOutFlags | ''
>;

export interface BetaFlag {
  readonly betaFlag: InternalBetaFlags;
  readonly optOutFlag: InternalOptOutFlags | undefined;
}
