import {parseGid} from '@shopify/admin-graphql-api-utilities';

import {type IdOrGidOrDynamicPath, type Resource} from '~/types';

/**
 * Converts a gid to just the id portion ("gid://shopify/Product/1" => "1")
 * Or a number to a string (1 => "1")
 * Or leaves any other string as is (":id" => ":id", "1" => "1", "new" => "new")
 */
export const normalizeId = (id: IdOrGidOrDynamicPath<Resource>) => {
  const str = `${id}`;
  if (str.startsWith('gid://')) {
    return parseGid(str);
  }

  return str;
};
